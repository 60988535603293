<template>
  <SlModal
    :id="id"
    :loading="isLoading"
    :title="$t('Web.LicenseDetails.IncreaseMaxUsers')"
    :subtitle="$t('Web.LicenseDetails.IncreaseMaxUsersDescription')"
    @show="onModalShow"
    @on-enter="handleSave"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content"
    >
      <SlValidate
        v-slot="{ invalid }"
        key="limitOfUsers"
        :rules="limitRules"
      >
        <SlControlInput
          v-model="requestedLimitModel"
          :min="currentLimit"
          :max="maxLimit"
          :label="$t('Web.LicenseDetails.MaxUsers')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </ValidationObserver>
    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSave">
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { UNLIM_USERS_COUNT } from '@/config/license';

export default {
  name: 'IncreaseMaxUsersModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.INCREASE_MAX_USERS,
      maxLimit: UNLIM_USERS_COUNT,
      requestedLimitModel: 0,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters ({
      currentLimit: 'initialization/maxUsersCount'
    }),
    limitRules() {
      return {
        required: true,
        between: {
          min: this.currentLimit,
          max: this.maxLimit,
          message: this.requestedLimitModel < this.maxLimit
            ? this.$t('Web.LicenseDetails.MinCountOfUsersRule')
            : this.$t('Web.LicenseDetails.MaxCountOfUsersRule')
        }
      };
    }
  },
  methods: {
    ...mapActions({
      setUserLimit: 'subscription/setUserLimit'
    }),
    async handleSave() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      try {
        await this.setUserLimit(this.requestedLimitModel);
      } finally {
        this.isLoading = false;
      }

      this.hideModal(this.id);
    },
    handleCancel() {
      this.requestedLimit = this.currentLimit;

      this.hideModal(this.id);
    },
    onModalShow() {
      this.requestedLimitModel = this.currentLimit;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
