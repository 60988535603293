<template>
  <SubPageWrapper empty-cols>
    <ContentBlock class="subscription">
      <div class="subpage__title heading-3-sb grey-100">
        <template>
          {{ $t('Web.LicenseDetails.Subscription') }}
        </template>
        <SlButton
          v-if="isFreePlan"
          variant="secondary"
          color="grey"
          @click="handleContactSales"
        >
          <template #prepend>
            <icon
              data="@sidebar/chat.svg"
              class="size-20 fill-off"
            />
          </template>
          {{ $t('Web.FreeVersion.TalkTo') }}
        </SlButton>
        <SlButton
          v-else
          variant="secondary"
          color="grey"
          @click="handleOpenChat"
        >
          <template #prepend>
            <icon
              data="@sidebar/chat.svg"
              class="size-20 fill-off"
            />
          </template>
          {{ $t('Web.LicenseDetails.ContactSupport') }}
        </SlButton>
      </div>
      <div class="subpage__content">
        <div class="subpage__section">
          <SlInfoSection
            v-if="isFreePlan"
            :label="$t('Web.FreeVersion.CurrentPlan')"
          >
            <template #value>
              <div class="info-section__value heading-4-sb accent-green-80">
                {{ $t('Web.FreeVersion.Free') }}
                <SlButton
                  v-tooltip="getFreePlanTooltip()"
                  size="sm"
                  variant="text"
                  color="grey"
                  icon
                >
                  <icon
                    data="@icons/info.svg"
                    class="color-grey-50 fill-off size-16"
                  />
                </SlButton>
              </div>
            </template>
          </SlInfoSection>
          <SlInfoSection
            v-else-if="licenseStatus"
            :value="expirationLocaleDate"
          >
            <template #label>
              <div class="body-1 grey-70">
                {{ $t('Web.LicenseDetails.ExpirationDate') }}
                <SlBadge :variant="licenseStatus.variant">
                  {{ licenseStatus.getLabel(expiresIn) }}
                </SlBadge>
              </div>
            </template>
          </SlInfoSection>
        </div>
        <div class="subpage__section subpage__section--grid">
          <SlInfoSection
            :label="$t('Web.LicenseDetails.CountOfActiveUsers')"
            :value="currentUsersCount"
          >
            <template
              v-if="$sl_isUserAdmin && !isFreePlan"
              #append
            >
              <SlButton
                variant="text"
                color="primary"
                @click="$sl_showAdminPortal"
              >
                <template #prepend>
                  <icon
                    data="@icons/plus.svg"
                    class="fill-off size-16"
                  />
                </template>
                {{ $t('Web.LicenseDetails.InviteUsers') }}
              </SlButton>
            </template>
          </SlInfoSection>
          <SlInfoSection
            :label="$t('Web.LicenseDetails.MaxUsers')"
            :value="maxUsers"
          >
            <template
              v-if="isMaxUsersIncreaseAvailable"
              #append
            >
              <SlButton
                variant="text"
                color="primary"
                @click="handleIncreaseMaxUsers"
              >
                <template #prepend>
                  <icon
                    data="@icons/zap.svg"
                    class="fill-off size-16"
                  />
                </template>
                {{ $t('Web.LicenseDetails.IncreaseLimit') }}
              </SlButton>
            </template>
          </SlInfoSection>
        </div>
      </div>
    </ContentBlock>
    <IncreaseMaxUsersModal />
  </SubPageWrapper>
</template>
<script>
import { mapGetters } from 'vuex';
import IncreaseMaxUsersModal from '@/components/Modals/IncreaseMaxUsersModal.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { SALES_DEMO_URL } from '@/config/shared/resources.config';
import { openLink } from '@/helpers/shared/webAPI';
import { getFreePlanTooltip } from '@/helpers/shared/tooltip';
import { UNLIM_USERS_COUNT } from '@/config/license';

export default {
  name: 'SubscriptionPage',
  components: {
    IncreaseMaxUsersModal
  },
  mixins: [modal],
  data() {
    return {
      licenseDetails: {
        ACTIVE: {
          getLabel: () => this.$t('Web.LicenseDetails.ActiveStatus'),
          variant: 'accent-green'
        },
        EXPIRED: {
          getLabel: () => this.$t('Web.LicenseDetails.ExpiredStatus'),
          variant: 'accent-red'
        },
        WARNING: {
          getLabel: (val) => (this.$t('Web.LicenseDetails.RemainStatus.val', {
            p1: this.$tc('Web.LicenseDetails.RemainStatus.p1', val, { n: val })
          })),
          variant: 'accent-yellow'
        }
      },
      licenseStatus: null
    };
  },
  computed: {
    ...mapGetters({
      isFreePlan: 'initialization/isFreePlan',
      expiresIn: 'initialization/expiresIn',
      expirationLocaleDate: 'initialization/expirationLocaleDate',
      currentUsersCount: 'initialization/currentUsersCount',
      maxUsersCount: 'initialization/maxUsersCount',
      needLicenseDetails: 'initialization/needLicenseDetails',
      increaseMaxUsersLimitEnabled: 'initialization/increaseMaxUsersLimitEnabled'
    }),
    maxUsers() {
      return this.isUserCountUnlimited ? '∞' : this.maxUsersCount;
    },
    isUserCountUnlimited() {
      return this.maxUsersCount === UNLIM_USERS_COUNT;
    },
    isMaxUsersIncreaseAvailable() {
      return !this.isUserCountUnlimited && this.increaseMaxUsersLimitEnabled && this.$sl_isUserAdmin && !this.isFreePlan;
    }
  },
  mounted() {
    if (!this.needLicenseDetails) {
      this.licenseStatus = this.licenseDetails.ACTIVE;

      return;
    }

    if (this.expiresIn > 0) {
      this.licenseStatus = this.licenseDetails.WARNING;
    } else {
      this.licenseStatus = this.licenseDetails.EXPIRED;
    }
  },
  methods: {
    getFreePlanTooltip() {
      return getFreePlanTooltip(this);
    },
    handleContactSales() {
      openLink(SALES_DEMO_URL, true);
    },
    handleOpenChat() {
      this.showModal(modalsId.REPORT_ISSUE);
    },
    handleIncreaseMaxUsers() {
      return this.showModal(modalsId.INCREASE_MAX_USERS);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.subpage__section {
  padding: 24px;
  border-bottom: 1px solid $grey-30;

  &--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .info-section__value {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
}

.subpage__content {
  border-top: 1px solid $grey-30;
}
</style>
